@charset "UTF-8";

.fullpage-container {

  .pc-bg {
    position: absolute;
    top: 50px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 200px;

    #inner-container {
      top: -60px;

      .section {
        img {
          display: block;
          height: 80vh;
          min-height: 300px;
        }
      }
    }

    .desc-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: stretch;
      color: #fff;
      height: 100%;

      h3 {
        font-weight: normal;
        font-size: 9rem;
        font-size: 3.5vw;
      }

      p {
        margin: 40px 0 50px;
        padding: 0;
        font-size: 3rem;
        font-size: 2.5vw;
      }

      .ios, .android, .apk {
        margin-top: 30px;
        display: block;

        img {
          width: 100%;
        }
      }

    }

    &.bg1 {
      background: url("//image.micoworld.net/46b91d8f0cc0e419a08458b600bbaa69") no-repeat center, linear-gradient(134deg,rgba(82,45,241,1) 0%,rgba(122,55,246,1) 100%);
      background: linear-gradient(135deg,#221b18,#6f4f42);
      background-size: cover;
    }

    &.bg2 {
      background: url("//image.micoworld.net/a73c275a03fe6127ea68649363e1486e") no-repeat center, linear-gradient(315deg,rgba(0,202,255,1) 0%,rgba(0,127,255,1) 100%);
      background: linear-gradient(90deg,#602b03 60%,#042077);
      background-size: cover;
    }

    &.bg3 {
      background: url("//image.micoworld.net/c285617bc4e55ee53e506f6feafb219c") no-repeat center, linear-gradient(135deg,rgba(255,0,163,1) 0%,rgba(255,137,77,1) 100%);
      background: linear-gradient(145deg, #3c1984, #6357d1);
      background-size: cover;
    }

    &.bg4 {
      background: url("//image.micoworld.net/f87d3e34e1cbe4ac97f73445500ba28e") no-repeat center, linear-gradient(135deg,rgba(0,200,225,1) 0%,rgba(49,255,148,1) 100%);
      background: radial-gradient(circle farthest-side at 70% 50%, #27beef 10%, #3c48aa 40%);
      background-size: cover;
    }

    &.bg5 {
      background: url("//image.micoworld.net/d1af358f2f9c493dba782f0ff8e974e8") no-repeat center, linear-gradient(135deg,rgba(255,134,44,1) 0%,rgba(255,193,62,1) 100%);
      background: linear-gradient(90deg,#4787ff,#953afe,#ff794a);
      background-size: cover;
    }
  }

  .mobile-bg {
    position: absolute;
    top: 50px;
    bottom: 100px;
    width: 100%;

    .swiper-container {
      height: 100%;

      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;


        .desc-box {
          position: relative;
          color: #fff;
          text-align: center;

          h3 {
            font-size: 16px;
            margin: 20px auto 5px;
          }

          p {
            font-size: 12px;
          }
        }

        .section {
          position: relative;
          text-align: center;

          img {
            width: 59.2vw;
          }
        }

        .bg1, .bg2, .bg3, .bg4, .bg5 {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 90px;
        }

        .bg1 {
          background: url("//image.micoworld.net/6526f5c706998731ca7ae05203b7713e") no-repeat center, linear-gradient(134deg,rgba(82,45,241,1) 0%,rgba(122,55,246,1) 100%);
          background: linear-gradient(135deg,#221b18,#6f4f42);
          background-size: cover;
        }

        .bg2 {
          background: url("//image.micoworld.net/eb968d40efee8503e590dfa65eb484a4") no-repeat center, linear-gradient(315deg,rgba(0,202,255,1) 0%,rgba(0,127,255,1) 100%);
          background: linear-gradient(90deg,#602b03,#042077);
          background-size: cover;
        }

        .bg3 {
          background: url("//image.micoworld.net/ab8b076947712137aae8b0a308a420d6") no-repeat center, linear-gradient(135deg,rgba(255,0,163,1) 0%,rgba(255,137,77,1) 100%);
          background: linear-gradient(145deg, #3c1984, #6357d1);
          background-size: cover;
        }

        .bg4 {
          background: url("//image.micoworld.net/b83269aa54be87738f2c0da0ba5d4eb6") no-repeat center, linear-gradient(135deg,rgba(0,200,225,1) 0%,rgba(49,255,148,1) 100%);
          background: radial-gradient(circle closest-side at 50% 50%,#27beef,#3c48aa 90%);
          background-size: cover;
        }

        .bg5 {
          background: url("//image.micoworld.net/554ab3b796372299ce17b21f5137d995") no-repeat center, linear-gradient(135deg,rgba(255,134,44,1) 0%,rgba(255,193,62,1) 100%);
          background: linear-gradient(90deg,#4787ff,#953afe,#ff794a);
          background-size: cover;
        }
      }

      .swiper-pagination-bullets {
        position: absolute;
        top: 65px;
      }
    }
  }

  .mobile-dl {
    position: absolute;
    bottom: 0;
    height: 100px;
    left: 20px;
    right: 20px;

    a {
      display: block;
      width: 100%;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
